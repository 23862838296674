import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ChampionshipsScreen from './ChampionshipsScreen';

const useStyles = makeStyles({

});

export default function AdminScreen({ user, notification }) {
  const classes = useStyles();
  return (
    <>
      <div>
        <div>{`Sveiks ${user.name}`}</div>
      </div>
      <ChampionshipsScreen user={user} notification={notification} />
    </>
  );
}
