import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  TextField, Button, Box, IconButton, Checkbox,
} from '@material-ui/core';
import Switch from '@material-ui/core/Switch';
import { wrappedFetch } from '../assets/helper';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  reportBugFormWrapper: {
    paddingTop: '6px',
    width: '80%',
    margin: 'auto',
    position: 'relative',
  },
  upload: {
    display: 'block',
    fontSize: '1rem',
    maxWidth: 220,
    cursor: 'pointer',
  },
  uploadText: {
    paddingRight: '10px',
    display: 'inline',
  },
  sudokuWrapper: {
    width: 500,
    height: 500,
    position: 'relative',
  },
  imageWrapper: {
    width: '100%',
    height: '100%',
  },
  sudokuImage: {
    width: '100%',
    height: '100%',
  },
  sudokuRow: {
    display: 'flex',
    flex: 1,
  },
  inputFieldWrapper: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',

  },
  inputField: {
    flex: 1,
    '& fieldset': {
      border: 'unset',
    },
  },
});

export default function ChampionshipsForm({
  notification, editId, setView,
}) {
  const [data, setData] = useState({ title: '', published: false, testing: false });
  const classes = useStyles();

  useEffect(() => {
    if (editId) {
      wrappedFetch(`championships/${editId}`).then((res) => {
        console.log({ res });
        if (res) {
          setData({
            title: res.title,
            published: res.published,
            testing: res.testing,
          });
        }
      });
    }
  }, []);

  const handleInputChange = (field, value, val) => {
    console.log({ value });
    console.log({ val });

    setData({ ...data, [field]: value });
  };

  const createChampionship = () => {
    wrappedFetch(editId ? `championships/${editId}` : 'championships', data, editId ? 'PUT' : 'POST').then((response) => {
      if (response.success === true) {
        setData({ title: '', published: '' });
        setView('table');
        notification('Veiksmīgi izpildīts', 'success');
      } else {
        notification('Kaut kas nogāja greizi', 'error');
      }
    }).catch((er) => {
      notification('Kaut kas nogāja greizi', 'error');
    });
  };

  const handleChange = (event) => {
    setData({ ...data, [event.target.name]: event.target.checked });
  };

  return (
    <Box>
      <form className={classes.root} noValidate autoComplete="off">
        <TextField
          onChange={(event) => handleInputChange('title', event.target.value)}
          value={data.title}
          id="title"
          label="Virsraksts"
        />
        <Box display="flex">
          <Box paddingRight="65px">Publicēts</Box>
          <Box>
            <Switch
              checked={!!data.published}
              onChange={handleChange}
              name="published"
              color="primary"
            />
          </Box>
        </Box>
        <Box display="flex">
          <Box>Mācību čempionāts</Box>
          <Box>
            <Switch
              checked={!!data.testing}
              disabled={!!data.testing}
              onChange={handleChange}
              name="testing"
              color="primary"
            />
          </Box>
        </Box>

      </form>
      <Button onClick={createChampionship} variant="contained" color="primary">
        {editId ? 'Rediģēt čempionātu' : 'Izveidot čempionātu'}
      </Button>
    </Box>

  );
}
