import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import Box from '@material-ui/core/Box';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { formatDate } from '../atoms/formatDate';

const useStyles = makeStyles({
  sudokuWrapper: {
    width: 500,
    height: 500,
    position: 'relative',
  },
  imageWrapper: {
    width: '100%',
    height: '100%',
  },
  sudokuImage: {
    width: '100%',
    height: '100%',
  },
  sudokuRow: {
    display: 'flex',
    flex: 1,
  },
  inputFieldWrapper: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',

  },
  inputField: {
    flex: 1,
    '& fieldset': {
      border: 'unset',
    },
  },
  root: {
    margin: '5px',
  },
});

export default function EndScreen({ championshipEndingTime, showAllPoints }) {
  const classes = useStyles();
  console.log({ showAllPoints });

  return (
    <div style={{
      display: 'flex', flexDirection: 'column', justifyContent: 'center', width: '100%', position: 'absolute', top: '0', bottom: '0',
    }}
    >
      <h3 style={{ textAlign: 'center' }}>Čempionāts veiksmīgi noslēdzies</h3>
      <h3 style={{ textAlign: 'center' }}>{`${new Date() < new Date(`${championshipEndingTime}Z`) ? 'Beigsies' : 'Beidzās'} ${formatDate(new Date(`${championshipEndingTime}Z`))}`}</h3>
      {showAllPoints?.length > 0 && (
      <Box style={{ alignSelf: 'center', width: '1200px', display: 'flex' }}>
          {showAllPoints.map((round) => (
            <TableContainer classes={{ root: classes.root }} component={Paper}>
              <Table className={classes.table} size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Numurs</TableCell>
                    <TableCell align="right">Nosaukums</TableCell>
                    <TableCell align="right">Iegūtie punkti</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {round.map((row) => (
                    <TableRow key={row.name}>
                      <TableCell>
                        {row.task_number}
                      </TableCell>
                      <TableCell align="right">{row.task_title}</TableCell>
                      <TableCell align="right">{`${row.task_earned_points}/${row.task_max_points}`}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ))}
      </Box>
      )}
    </div>
  );
}
