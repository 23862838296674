import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import Typography from '@material-ui/core/Typography';
import { isMobile } from 'react-device-detect';
import CloseDialogAction from '../atoms/CloseDialogAction';

const useStyles = makeStyles((theme) => ({
  title: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: 0,
    top: 0,
    color: theme.palette.grey[500],
    zIndex: '1',
  },
  dialogContent: {
    padding: ({ noPadding }) => (noPadding ? 0 : '15px 15px'),
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    flexDirection: ({ CSS }) => (CSS.flexDirection ? CSS.flexDirection : 'row'),
    height: isMobile ? '100%' : ({ dynamicSize }) => (dynamicSize ? 'auto' : 510),
    overflow: 'auto',
    justifyContent: ({ wrapContent }) => (wrapContent ? 'space-evenly' : 'normal'),
    flexWrap: ({ wrapContent }) => (wrapContent ? 'wrap' : 'nowrap'),
    fontSize: '15px',
    alignItems: ({ wrapContent }) => (wrapContent ? 'center' : 'normal'),
    '& .MuiTab-textColorPrimary.Mui-selected': {
      color: theme.palette.settings.list.selectedColor,
    },
  },
  dialogActions: {
    margin: 0,
    padding: '16px',
  },
  dialog: {
    '& .MuiDialog-paperScrollPaper': {
      width: ({ CSS }) => CSS.width,
      height: ({ CSS }) => CSS.height,
      'padding-top': ({ CSS }) => CSS.paddingTop,
      backgroundColor: theme.palette.background.paper,
    },
  },
}));

const DialogTitle = (({
  children, classes, ...other
}) => (
  <MuiDialogTitle disableTypography className={classes.title} {...other}>
    <Typography variant="h6">{children}</Typography>
  </MuiDialogTitle>
));

export default ({
  children, open, setOpen, title, footer, cantRemove, wrapContent, noPadding, dynamicSize, ...CSS
}) => {
  const classes = useStyles({
    wrapContent, noPadding, dynamicSize, CSS,
  });

  const handleClose = () => {
    if (!cantRemove) {
      setOpen(false);
    }
  };

  return (
    <div>
      <Dialog
        fullWidth={!dynamicSize}
        fullScreen={isMobile}
        maxWidth="md"
        onClose={handleClose}
        aria-labelledby="dialog"
        open={open}
        className={classes.dialog}
      >
        {!cantRemove ? (
          <CloseDialogAction onClick={handleClose} />
        ) : null}
        {title && (
          <DialogTitle id="dialog-title" classes={classes}>
            {title}
          </DialogTitle>
        )}
        <MuiDialogContent className={classes.dialogContent}>
          {children}
        </MuiDialogContent>
        { footer && (
          <MuiDialogActions className={classes.dialogActions}>
            {footer}
          </MuiDialogActions>
        )}
      </Dialog>
    </div>
  );
};
