/* eslint-disable no-nested-ternary */
import React from 'react';
import {
  Box,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

const FancyBackdrop = ({ showLoader, loaderText }) => {
  const classes = useStyles();

  return (
    <Backdrop className={classes.backdrop} open={showLoader}>
      <CircularProgress color="inherit" />

      {loaderText !== null && (
        <Box
          top={90}
          left={0}
          bottom={0}
          right={0}
          position="absolute"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Typography variant="h6" component="div">
            {loaderText}
          </Typography>
        </Box>
      )}
    </Backdrop>
  );
};

export default FancyBackdrop;
