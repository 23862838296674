import React from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { Box } from '@material-ui/core';

const GlobalStyle = createGlobalStyle`
  html, body {
    background-color: rgb(255,255,255);
    background-size: contain;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    //display: flex;
    //align-items: center;
  }
  `;

const MainContainer = styled.div`
  height: 100%;
  align-items: center;
  display: flex;
  justify-content: center;
  overflow: hidden;
`;

const UnauthorizedSpaceWrapper = ({ children }) => (
  <MainContainer>
    <GlobalStyle />
    <Box style={{ width: '100%', height: '100%' }}>
      {children}
    </Box>
  </MainContainer>
);

export default UnauthorizedSpaceWrapper;
