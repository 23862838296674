import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Button, Box, IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { wrappedFetch } from '../assets/helper';
import Modal from './Modal';
import { formatDate } from '../atoms/formatDate';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  buttons: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
});
const TasksTable = ({
  setEditTaskId, notification, setView, roundNumber, champId, setRoundId,
}) => {
  const classes = useStyles();
  const [rows, setRows] = useState([]);
  const [roundData, setRoundData] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);

  const handleEditTask = (id) => {
    setEditTaskId(id);
    setRoundId(roundData.id);
    setView('form');
  };

  useEffect(() => {
    wrappedFetch(`rounds/${champId}/${roundNumber}`).then((res) => {
      console.log({ res });
      if (res && res.tasks) {
        setRoundData(res);
        setRows(res.tasks);
      }
    });
  }, []);

  const switchTaskForm = () => {
    setView('form');
    setRoundId(roundData.id);
  };

  const handleEditRound = () => {
    setView('rounds_form');
    setRoundId(roundData.id);
  };

  const openDeleteModal = (id, e) => {
    e.stopPropagation();
    setModalOpen(id);
  };

  const handleDeleteTask = () => {
    setView(null);
    wrappedFetch(`tasks/${modalOpen}`, null, 'DELETE').then((response) => {
      setView('table');
      setModalOpen(false);
      if (response.success === true) {
        notification('Veiksmīgi izpildīts', 'success');
      } else {
        notification('Kaut kas nogāja greizi', 'error');
      }
    }).catch((er) => {
      notification('Kaut kas nogāja greizi', 'error');
    });
  };

  const modalButtons = (
    <div className={classes.buttons}>
      <Button
        onClick={() => setModalOpen(false)}
        variant="contained"
        color="secondary"
        style={{ marginRight: 0 }}
      >
        Nē
      </Button>
      <Button
        onClick={handleDeleteTask}
        variant="contained"
        color="primary"
      >
        Jā
      </Button>
    </div>
  );

  console.log({ rows });

  const pointsInARow = (() => {
    let points = 0;
    rows.forEach((el) => {
      points += el.points;
    });
    return points;
  })();

  return (
    <>
      <Box
        style={{
          width: 'fit-content', boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px', margin: 'auto', marginBottom: '25px', borderRadius: '7px', padding: 5, cursor: 'pointer',
        }}
        onClick={handleEditRound}
      >
        <Box style={{ display: 'flex' }}>
          <Box style={{ padding: 5 }}>Sākuma laiks:</Box>
          <Box style={{ padding: 5 }}>{(roundData.starting_time && formatDate(new Date(`${roundData.starting_time}Z`), roundData.championship.testing)) || 'Nav specificēts'}</Box>
        </Box>
        <Box style={{ display: 'flex' }}>
          <Box style={{ padding: 5 }}>Beigu laiks:</Box>
          <Box style={{ padding: 5 }}>{(roundData.ending_time && formatDate(new Date(`${roundData.ending_time}Z`), roundData.championship.testing)) || 'Nav specificēts'}</Box>
        </Box>
        <Box style={{ display: 'flex' }}>
          <Box style={{ padding: 5 }}>Kopā punkti par roundu:</Box>
          <Box style={{ padding: 5 }}>{pointsInARow}</Box>
        </Box>
      </Box>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Numurs</TableCell>
              <TableCell align="center">Virsraksts</TableCell>
              <TableCell align="center">Punkti</TableCell>
              <TableCell align="center">Izveidots</TableCell>
              <TableCell align="center">Rediģēts</TableCell>
              <TableCell align="center">Rediģēt</TableCell>
              <TableCell align="center">Dzēst</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.sort((a, b) => a.number - b.number).map((row) => (
              <TableRow key={row.id}>
                <TableCell component="th" scope="row">
                  {row.number}
                </TableCell>
                <TableCell align="center">{row.title}</TableCell>
                <TableCell align="center">{row.points}</TableCell>
                <TableCell align="center">{row.created_at}</TableCell>
                <TableCell align="center">{row.updated_at}</TableCell>
                <TableCell align="center">
                  <Button onClick={() => handleEditTask(row.id)} variant="contained" color="primary">
                    Rediģēt
                  </Button>
                </TableCell>
                <TableCell align="center">
                  <IconButton onClick={(e) => openDeleteModal(row.id, e)} aria-label="delete">
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Box>
        <Button onClick={switchTaskForm} variant="contained" color="primary">
          + Izveidot uzdevumu
        </Button>
      </Box>
      <Modal
        title="Uzmanību"
        open={!!modalOpen}
        dynamicSize
        footer={modalButtons}
        setOpen={setModalOpen}
      >
        <p>Vai tiešām vēlies dzēst šo uzdevumu</p>
      </Modal>
    </>
  );
};

export default TasksTable;
