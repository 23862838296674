import React, { useEffect, useState } from 'react';
import {
  Box, Collapse, IconButton,
} from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import CloseIcon from '@material-ui/icons/Close';

const AnimatedAlert = ({
  text, title, severity, timer, onClose, close, className,
}) => {
  // initially set
  const [alert, setAlert] = useState({
    text,
    title,
    severity,
    timer,
  });
  const closeAlert = () => {
    setAlert({
      text: null,
      title: null,
    });
    if (onClose) onClose();
  };

  useEffect(() => {
    let clock;
    if (timer) {
      clock = setTimeout(() => {
        closeAlert();
      }, timer);
    }
    return () => clearTimeout(clock);
  }, [alert.text, timer, closeAlert]);

  return (
    <Collapse in={!!(alert.text)} className={className}>
      {alert.text && (
        <Box mb={1}>
          <Alert
            severity={alert.severity}
            action={(close !== false
              && (
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={closeAlert}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
              )
                        )}
          >
            {alert.title && <AlertTitle>{alert.title}</AlertTitle>}
            {alert.text}
          </Alert>
        </Box>
      )}
    </Collapse>
  );
};

export default AnimatedAlert;
