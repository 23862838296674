import React, { useState } from 'react';
import './App.css';
import CssBaseline from '@material-ui/core/CssBaseline';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import LoginScreen from './organisms/LoginScreen';
import AdminScreen from './organisms/AdminScreen';
import PlayerScreen from './organisms/PlayerScreen';
import themes from './assets/themes/index';
import { get } from './assets/localStorage';
import CustomizedSnackbars from './atoms/Snackbar';

function App() {
  const [user, setUser] = useState({ authenticated: false });
  const [appearance, setAppearance] = useState('light');
  const [title, setTitle] = useState(null);
  const [notificationType, setNotificationType] = useState(false);
  const notification = (titlee, type) => {
    setTitle(titlee);
    setNotificationType(type);
  };

  const prefersDarkMode = window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light';

  const paleteType = (appearance || get('appearance') || prefersDarkMode) === 'device' ? prefersDarkMode : (appearance || get('appearance') || prefersDarkMode);
  const selectedPalette = themes[paleteType];

  const theme = React.useMemo(
    () => createMuiTheme(selectedPalette),
    [appearance],
  );

  console.log({ user });
  const logout = () => {
    setUser({ authenticated: false });
    localStorage.removeItem('body');
  };
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {!user.authenticated && <LoginScreen setUser={setUser} />}
      {user.authenticated && user.role === 'admin' && <AdminScreen notification={notification} user={user} />}
      {user.authenticated && user.role === 'player' && <PlayerScreen notification={notification} user={user} />}
      <CustomizedSnackbars title={title} open={notificationType} setOpen={setNotificationType} />
      {user.authenticated && (
      <div style={{ position: 'absolute', top: 30, right: 30 }}>
        <Button
          onClick={logout}
          variant="contained"
          color="secondary"
        >
          Izlogoties
        </Button>
      </div>
      ) }
    </ThemeProvider>
  );
}

export default App;
