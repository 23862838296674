import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Button, IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import Switch from '@material-ui/core/Switch';
import DoneIcon from '@material-ui/icons/Done';
import ClearIcon from '@material-ui/icons/Clear';
import Modal from './Modal';
import { wrappedFetch } from '../assets/helper';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  buttons: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  bodyRow: {
    cursor: 'pointer',
  },
});
const FilledChampionshipsTable = ({
  notification, setView, champId, setChampId, setFilledChampId,
}) => {
  const classes = useStyles();
  const [rows, setRows] = useState([]);

  const createData = (data) => ({
    ...data,
  });

  useEffect(() => {
    wrappedFetch(`filled-championships/${champId}`).then((res) => {
      console.log({ res });
      if (res.length) {
        const data = [];
        res.forEach((el) => {
          data.push(createData(el));
        });
        setRows(data);
      }
    });
  }, []);

  const handleReviewChampionship = (id, e) => {
    e.stopPropagation();
    setFilledChampId(id);
  };

  const getPointsForRound = (pointsString, roundIndex) => {
    const pointsJson = JSON.parse(pointsString);
    const round = pointsJson[roundIndex];
    return round.reduce((a, b) => a + b, 0);
  };

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Vārds Uzvārds</TableCell>
            <TableCell align="center">Punkti 1. raundā</TableCell>
            <TableCell align="center">Punkti 2. raundā</TableCell>
            <TableCell align="center">Punkti 3. raundā</TableCell>
            <TableCell align="center">Punkti kopā</TableCell>
            <TableCell align="center">Izveidots</TableCell>
            <TableCell align="center">Rediģēts</TableCell>
            <TableCell align="center">Apstiprināts</TableCell>
            <TableCell align="center">Pārskatīt</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow className={classes.bodyRow} key={row.id}>
              <TableCell component="th" scope="row">
                {row.user.name}
              </TableCell>
              <TableCell align="center">{getPointsForRound(row.points, 0)}</TableCell>
              <TableCell align="center">{getPointsForRound(row.points, 1)}</TableCell>
              <TableCell align="center">{getPointsForRound(row.points, 2)}</TableCell>
              <TableCell align="center">{getPointsForRound(row.points, 0) + getPointsForRound(row.points, 1) + getPointsForRound(row.points, 2)}</TableCell>
              <TableCell align="center">{row.created_at}</TableCell>
              <TableCell align="center">{row.updated_at}</TableCell>
              <TableCell align="center">{row.confirmed ? <DoneIcon style={{ color: 'green' }} fontSize="large" color="inherit" /> : <ClearIcon fontSize="large" color="error" />}</TableCell>
              <TableCell align="center">
                <Button onClick={(e) => handleReviewChampionship(row.id, e)} variant="contained" color="primary">
                  Pārskatīt
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default FilledChampionshipsTable;
