import React, { useState, useEffect } from 'react';
import DateFnsUtils from '@date-io/date-fns';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button, Box,
} from '@material-ui/core';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { wrappedFetch } from '../assets/helper';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  reportBugFormWrapper: {
    paddingTop: '6px',
    width: '80%',
    margin: 'auto',
    position: 'relative',
  },
  upload: {
    display: 'block',
    fontSize: '1rem',
    maxWidth: 220,
    cursor: 'pointer',
  },
  uploadText: {
    paddingRight: '10px',
    display: 'inline',
  },
  sudokuWrapper: {
    width: 500,
    height: 500,
    position: 'relative',
  },
  imageWrapper: {
    width: '100%',
    height: '100%',
  },
  sudokuImage: {
    width: '100%',
    height: '100%',
  },
  sudokuRow: {
    display: 'flex',
    flex: 1,
  },
  inputFieldWrapper: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',

  },
  inputField: {
    flex: 1,
    '& fieldset': {
      border: 'unset',
    },
  },
});

export default function RoundsForm({ roundId, goBackToTable, notification }) {
  const [data, setData] = useState({ starting_time: null, ending_time: null, testing: false });
  const classes = useStyles();

  useEffect(() => {
    if (roundId) {
      wrappedFetch(`rounds/${roundId}`).then((res) => {
        console.log({ res });
        if (res) {
          setData({
            starting_time: res.starting_time ? `${res.starting_time}Z` : null,
            ending_time: res.ending_time ? `${res.ending_time}Z` : null,
            testing: res.championship.testing,
          });
        }
      });
    }
  }, []);

  const handleDateChange = (field, value) => {
    value.setSeconds(0, 0);
    setData({ ...data, [field]: value });
  };

  const editRound = () => {
    wrappedFetch(`rounds/${roundId}`, data, 'PUT').then((response) => {
      if (response.success === true) {
        setData({ starting_time: '', ending_time: '' });
        goBackToTable();
        notification('Veiksmīgi izpildīts', 'success');
      } else {
        notification('Kaut kas nogāja greizi', 'error');
      }
    }).catch((er) => {
      notification('Kaut kas nogāja greizi', 'error');
    });
  };

  return (
    <Box>
      <Button onClick={goBackToTable} variant="contained" color="primary">
        Atpakaļ
      </Button>
      <form className={classes.root} noValidate autoComplete="off">
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Box>
            {data.testing ? '' : (
              <KeyboardDatePicker
                margin="normal"
                id="date-picker-dialog"
                label="Sākuma datums"
                format="dd/MM/yyyy"
                value={data.starting_time}
                onChange={(value) => handleDateChange('starting_time', value)}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
            )}
            <KeyboardTimePicker
              margin="normal"
              id="time-picker"
              label="Sākuma laiks"
              ampm={false}
              value={data.starting_time}
              onChange={(value) => handleDateChange('starting_time', value)}
              KeyboardButtonProps={{
                'aria-label': 'change time',
              }}
            />
          </Box>
          <Box>
            {data.testing ? '' : (
              <KeyboardDatePicker
                margin="normal"
                id="date-picker-dialog"
                label="Beigu datums"
                format="dd/MM/yyyy"
                value={data.ending_time}
                onChange={(value) => handleDateChange('ending_time', value)}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
            )}
            <KeyboardTimePicker
              margin="normal"
              id="time-picker"
              label="Beigu laiks"
              value={data.ending_time}
              ampm={false}
              onChange={(value) => handleDateChange('ending_time', value)}
              KeyboardButtonProps={{
                'aria-label': 'change time',
              }}
            />
          </Box>
        </MuiPickersUtilsProvider>

      </form>
      <Button onClick={editRound} variant="contained" color="primary">
        Rediģēt roundu
      </Button>
    </Box>

  );
}
