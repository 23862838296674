export function formatDate(date, testing) {
  if (testing) {
    return `${date.getHours() / 10.0 < 1 ? `0${date.getHours()}` : date.getHours()}:${date.getMinutes() / 10.0 < 1 ? `0${date.getMinutes()}` : date.getMinutes()}`;
  }
  return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()} ${date.getHours() / 10.0 < 1 ? `0${date.getHours()}` : date.getHours()}:${date.getMinutes() / 10.0 < 1 ? `0${date.getMinutes()}` : date.getMinutes()}`;
}

export function formatTime(date) {
  return `${date.getHours() / 10.0 < 1 ? `0${date.getHours()}` : date.getHours()}:${date.getMinutes() / 10.0 < 1 ? `0${date.getMinutes()}` : date.getMinutes()}`;
}

export function secondsToHms(d) {
  d = Number(d);
  const h = Math.floor(d / 3600);
  const m = Math.floor((d % 3600) / 60);
  const s = Math.floor((d % 3600) % 60);

  const hDisplay = h > 0 ? h + (h === 1 ? ' stundas ' : ' stundām ') : '';
  const mDisplay = m > 0 ? m + (m === 1 ? ' minūtes ' : ' minūtēm ') : '';
  const sDisplay = s > 0 ? s + (s === 1 ? ' sekundes' : ' sekundēm') : '';
  return hDisplay + mDisplay + sDisplay;
}

export function secondsToHmsShort(d) {
  d = Number(d);
  const h = `0${Math.floor(d / 3600)}`;
  const m = `0${Math.floor((d % 3600) / 60)}`;
  const s = `0${Math.floor((d % 3600) % 60)}`;

  return `${h.substr(-2)}:${m.substr(-2)}:${s.substr(-2)}`;
}
