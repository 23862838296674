/* eslint-disable no-nested-ternary */
import React, { useState, useRef, useEffect } from 'react';
import {
  Box,
  Button,
  TextField,
  Paper,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  FormControl,
} from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { BsArrowRepeat } from 'react-icons/bs';
import UnauthorizedSpaceWrapper from '../molecules/UnauthorizedSpaceWrapper';
import AnimatedAlert from '../molecules/AnimatedAlert';
import Logo from '../assets/images/logo.jpg';
import Modal from '../molecules/Modal';
import { login } from '../assets/helper';
import FancyBackdrop from '../atoms/FancyBackdrop';

const { localStorage } = window;

const useStyles = makeStyles((theme) => ({
  loginArea: {
    padding: 24,
    width: '480px',
    position: 'relative',
    display: 'inline-block',

    '@media (max-height: 768px)': {
      padding: '10px 24px 20px 24px',
    },

    [theme.breakpoints.down('xs')]: {
      width: '90%',
    },
  },
  alertBanner: {
    width: '480px',
    [theme.breakpoints.down('xs')]: {
      width: '90%',
    },
  },
  loginForm: {
    display: 'flex',
    flexDirection: 'column',
  },
  input: {
    marginBottom: 20,
    '& .MuiInputBase-formControl': {
      height: 56,
      background: theme.palette.loginScreen.input.background,
    },

    '& .MuiOutlinedInput-root.Mui-focused fieldset': {
      borderColor: theme.palette.loginScreen.input.color,
    },
    '& .MuiFormLabel-root.Mui-focused': {
      color: theme.palette.loginScreen.input.color,
    },

    '& .MuiOutlinedInput-input:-webkit-autofill': {
      '-webkit-box-shadow': theme.palette.loginScreen.input.autofill,
      borderRadius: theme.palette.loginScreen.input.borderRadius,
    },

    '@media (max-height: 768px)': {
      '& .MuiFormLabel-root:not(.Mui-focused):not(.MuiFormLabel-filled)': {
        transform: 'translate(14px, 20px) scale(1)',
      },
    },
  },
  button: {
    height: 54,

    '@media (max-height: 768px)': {
      height: 44,
    },
  },
  modalButton: {
    padding: '10px 25px',
  },
  version: {
    position: 'absolute',
    color: '#466e81',
    bottom: '3px',
    right: '5px',
    fontSize: '12px',
  },
  signupLink: {
    color: '#a6d8d8',
    fontSize: '15px',
  },
  signupLinkText: {
    color: '#fff',
    width: '100%',
    textAlign: 'center',
    padding: 24,
    fontSize: '16px',
    minHeight: '74px',
  },
  title: {
    fontWeight: '100',
    textAlign: 'center',
    marginBottom: '10px',

    [theme.breakpoints.down('lg')]: {
      fontSize: 28,
    },

    '@media (max-height: 768px)': {
      fontSize: 22,
    },
  },
  logo: {
    width: 360,
    paddingBottom: 15,

    '@media (max-height: 768px)': {
      paddingBottom: 0,
      width: 240,
    },
  },
  login: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: '330px',
  },
  loginWrapper: {
    paddingBottom: 100,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    '@media (max-height: 380px)': {
      justifyContent: 'unset',
    },
    height: '100%',
    overflow: 'auto',
    [theme.breakpoints.down('sm')]: {
      paddingBottom: '30px',
    },

    [theme.breakpoints.down('xs')]: {
      width: '100%',
      margin: 'auto',
    },

    '@media (max-height: 768px)': {
      paddingBottom: 20,
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  modal: {
    width: '450px',
    height: '65px',
    display: 'flex',
    alignItems: 'center',
  },
}));

const LoginScreen = ({ setUser }) => {
  const classes = useStyles();
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showLoader, setLoader] = useState(false);
  const [loaderText, setLoaderText] = useState(null);
  const [error, setError] = useState(null);
  const passwordInputRef = useRef(false);

  const handleChange = (event, prop) => {
    switch (prop) {
      case 'email':
        setEmail(event.target.value);
        break;
      case 'password':
        setPassword(event.target.value);
        break;
      default:
        break;
    }
  };

  console.log({ email, password });
  console.log({ error });
  console.log({ loaderText });
  console.log({ showLoader });

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
    setTimeout(() => {
      passwordInputRef.current.selectionStart = password.length;
      passwordInputRef.current.selectionEnd = password.length;
    }, 0);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const submitLogin = () => {
    setLoader(true);
    setLoaderText('Ielogojos..');
    setError(null);
    login({ email, password }).then((response) => {
      console.log({ response });
      if (response.token && response.success === true) {
        setUser({
          ...response.user,
          authenticated: true,
        });
      } else {
        setError(response.message || 'Serveris šobrīd nestrādā');
      }
      setLoader(false);
      setLoaderText(null);
    }).catch((er) => {
      console.log({ er });
      setLoader(false);
      setError('Serveris šobrīd nestrādā');
    });
  };

  useEffect(() => {
    if (localStorage.getItem('body')) {
      submitLogin();
    }
  }, []);

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      submitLogin();
    }
  };

  return (
    <UnauthorizedSpaceWrapper>
      <FancyBackdrop showLoader={showLoader} loaderText={loaderText} />
      <Box className={classes.loginWrapper}>
        <Box className={classes.login}>
          <img src={Logo} alt="Logo" className={classes.logo} />
          {error
            && <AnimatedAlert className={classes.alertBanner} text={error} severity="error" close={false} />}
          <Paper elevation={3} className={classes.loginArea} style={{ display: 'block' }}>
            <Typography variant="h4" className={classes.title}>Autorizēties čempionātam</Typography>

            <form
              noValidate
              autoComplete="off"
              className={classes.loginForm}
            >
              <TextField
                className={classes.input}
                onChange={(e) => handleChange(e, 'email')}
                id="email"
                label="Tavs e-pasts"
                variant="outlined"
                // error={(auth.failed === true && !email)}
                onKeyDown={handleKeyDown}
                value={email}
              />

              <FormControl
                className={classes.input}
                variant="outlined"
                // error={(auth.failed === true && !password)}
              >
                <InputLabel htmlFor="outlined-adornment-password">Tava parole</InputLabel>
                <OutlinedInput
                  inputRef={passwordInputRef}
                  id="outlined-adornment-password"
                  type={showPassword ? 'text' : 'password'}
                  value={password}
                  onChange={(e) => handleChange(e, 'password')}
                  onKeyDown={handleKeyDown}
                  endAdornment={(
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                    )}
                  labelWidth={98}
                />
              </FormControl>

              <Button
                className={classes.button}
                onClick={() => submitLogin()}
                variant="contained"
                color="primary"
                disabled={showLoader}
              >
                {showLoader ? 'Ielādējas' : 'Ielogoties'}
              </Button>
            </form>
            <Box className={classes.version}>
              Version 1
            </Box>
          </Paper>
        </Box>
      </Box>
      {/* <Modal title="Warning" cantRemove open={!!unsupportedBrowserModal || !!initRequestModal} dynamicSize footer={initRequestModal && !unsupportedBrowserModal && resetButton}>
        <div className={classes.modal}>
          {unsupportedBrowserModal || initRequestModal}
        </div>
      </Modal> */}
    </UnauthorizedSpaceWrapper>
  );
};

export default LoginScreen;
