import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import Box from '@material-ui/core/Box';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { formatDate, secondsToHms } from '../atoms/formatDate';

const useStyles = makeStyles({
  sudokuWrapper: {
    width: 500,
    height: 500,
    position: 'relative',
  },
  imageWrapper: {
    width: '100%',
    height: '100%',
  },
  sudokuImage: {
    width: '100%',
    height: '100%',
  },
  sudokuRow: {
    display: 'flex',
    flex: 1,
  },
  inputFieldWrapper: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',

  },
  inputField: {
    flex: 1,
    '& fieldset': {
      border: 'unset',
    },
  },
});

export default function WaitingScreen({
  roundNumber, championshipStartingTime, startsAfterDynamical, showPoints,
}) {
  const classes = useStyles();
  const [seconds, setSeconds] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setSeconds((seconds2) => seconds2 + 1);
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div style={{
      display: 'flex', flexDirection: 'column', justifyContent: 'center', width: '100%', position: 'absolute', top: '0', bottom: '0',
    }}
    >
      <h3 style={{ textAlign: 'center' }}>{`${roundNumber}. raunds`}</h3>
      <h3 style={{ textAlign: 'center' }}>{`Sāksies ${formatDate(new Date(`${championshipStartingTime}Z`))}`}</h3>
      <h3 style={{ textAlign: 'center' }}>{`Pēc ${secondsToHms(startsAfterDynamical())}`}</h3>
      {showPoints?.length > 0 && (
      <Box style={{ alignSelf: 'center', width: '600px' }}>
        <TableContainer component={Paper}>
          <Table className={classes.table} size="small">
            <TableHead>
              <TableRow>
                <TableCell>Numurs</TableCell>
                <TableCell align="right">Nosaukums</TableCell>
                <TableCell align="right">Iegūtie punkti</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {showPoints.map((row) => (
                <TableRow key={row.name}>
                  <TableCell>
                    {row.task_number}
                  </TableCell>
                  <TableCell align="right">{row.task_title}</TableCell>
                  <TableCell align="right">{`${row.task_earned_points}/${row.task_max_points}`}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      )}
    </div>
  );
}
