import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import { wrappedFetch } from '../assets/helper';
import ChampionshipsTable from '../molecules/ChampionshipsTable';
import ChampionshipsForm from '../molecules/ChampionshipsForm';
import RoundsScreen from './RoundsScreen';
import FilledChampionshipsTable from '../molecules/FilledChampionshipsTable';
import FilledChampionshipReview from '../molecules/FilledChampionshipReview';

const useStyles = makeStyles({

});

export default function AdminScreen({ user, notification }) {
  const classes = useStyles();
  const [rows, setRows] = useState([]);
  const [view, setView] = useState('table');
  const [editId, setEditId] = useState(null);
  const [championshipOpen, setChampionshipOpen] = useState(null);
  const [filledChampionshipOpen, setFilledChampionshipOpen] = useState(null);

  const createData = (data) => ({
    ...data,
  });

  useEffect(() => {
    if (view === 'table') {
      wrappedFetch('championships').then((res) => {
        console.log({ res });
        if (res.length) {
          const data = [];
          res.forEach((el) => {
            data.push(createData(el));
          });
          setRows(data);
        }
      });
    }
  }, [view]);

  console.log({ rows });

  const switchTaskForm = () => {
    if (filledChampionshipOpen) {
      setFilledChampionshipOpen(null);
    } else {
      setView(view === 'table' ? 'form' : 'table');
      setEditId(null);
      if (view === 'filled') {
        setChampionshipOpen(null);
      }
    }
  };

  return (
    <>
      {championshipOpen && view !== 'filled' ? (
        <RoundsScreen notification={notification} user={user} champId={championshipOpen} setChampId={setChampionshipOpen} />
      ) : (
        <div>
          <Button onClick={switchTaskForm} variant="contained" color="primary">
            {view === 'table' ? 'Izveidot čempionātu' : 'Atpakaļ'}
          </Button>
          {view === 'table' && <ChampionshipsTable notification={notification} rows={rows} setEditId={setEditId} setView={setView} setChampionshipOpen={setChampionshipOpen} />}
          {view === 'form' && <ChampionshipsForm notification={notification} user={user} editId={editId} setView={setView} />}
        </div>
      )}
      {championshipOpen && view === 'filled' && !filledChampionshipOpen ? <FilledChampionshipsTable notification={notification} setView={setView} champId={championshipOpen} setChampId={setChampionshipOpen} setFilledChampId={setFilledChampionshipOpen} /> : '' }
      {championshipOpen && view === 'filled' && filledChampionshipOpen ? <FilledChampionshipReview notification={notification} setView={setView} champId={championshipOpen} setFilledChampId={setFilledChampionshipOpen} filledChampId={filledChampionshipOpen} /> : '' }
    </>
  );
}
