import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { FaTimes } from 'react-icons/fa';
import { Box, IconButton } from '@material-ui/core';

const useStyles = makeStyles({
  closeButton: {
    padding: '8px',
  },

  closeButtonContainer: {
    position: 'absolute',
    top: '5px',
    right: '5px',
    zIndex: 1,
  },
});

const CloseDialogAction = ({ onClick, containerStyle, buttonStyle }) => {
  const classes = useStyles();
  return (
    <Box className={`${classes.closeButtonContainer} ${containerStyle}`}>
      <IconButton
        aria-label="close"
        className={`${classes.closeButton} ${buttonStyle}`}
        onClick={onClick}
      >
        <FaTimes />
      </IconButton>
    </Box>
  );
};

export default CloseDialogAction;
