import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import {
  Button, IconButton, Box, TextField, Backdrop,
} from '@material-ui/core';
import Pdf from 'react-to-pdf';
import { wrappedFetch } from '../assets/helper';
import FancyBackdrop from '../atoms/FancyBackdrop';

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  buttons: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  bodyRow: {
    cursor: 'pointer',
  },
  sudokuWrapper: {
    width: 500,
    height: 500,
    position: 'relative',
    margin: '0 20px',
  },
  imageWrapper: {
    width: '100%',
    height: '100%',
  },
  sudokuImage: {
    width: '100%',
    height: '100%',
  },
  sudokuRow: {
    display: 'flex',
    flex: 1,
  },
  inputFieldWrapper: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    padding: ({ marginEnabled }) => (marginEnabled ? 54 : 0),
  },
  inputField: {
    flex: 1,
    '& input': {
      textAlign: 'center',
      color: '#7777DD',
    },
    '& fieldset': {
      border: 'unset',
    },
  },
  correctLength: {
    '& input': {
      fontSize: ({ marginEnabled }) => (marginEnabled ? 26 : 36),
      padding: ({ marginEnabled }) => (marginEnabled ? '6px 14px' : '6px 17px'),
    },
  },
  wideLength: {
    '& input': {
      fontSize: ({ marginEnabled }) => (marginEnabled ? 18 : 25),
      padding: ({ marginEnabled }) => (marginEnabled ? '13px 5px' : '13px 7px'),
    },
  },
  widerLength: {
    '& input': {
      fontSize: ({ marginEnabled }) => (marginEnabled ? 10 : 16),
      padding: ({ marginEnabled }) => (marginEnabled ? '18px 4px' : '18px 5px'),
    },
  },
  incorrectValue: {
    '& input': {
      color: 'red',
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

const FilledChampionshipReview = ({
  notification, setFilledChampId, filledChampId,
}) => {
  const [data, setData] = useState(null);
  const [taskIndex, setTaskIndex] = useState(null);
  const [roundIndex, setRoundIndex] = useState(null);
  const [image, setImage] = useState(null);
  const [filledFields, setFilledFields] = useState(null);
  const [correctFields, setCorrectFields] = useState(null);
  const [points, setPoints] = useState(null);
  const [showLoader, setLoader] = useState(false);
  const realRoundIndex = roundIndex || 0;
  const realTaskIndex = taskIndex || 0;
  const classes = useStyles({ marginEnabled: data?.marginEnabled || false });

  useEffect(() => {
    setLoader(true);
    wrappedFetch(`filled-championships/review/${filledChampId}/${realRoundIndex}/${realTaskIndex}`).then((res) => {
      setLoader(false);
      console.log({ res });
      setData(res);
      setTaskIndex(0);
      setRoundIndex(0);
    });
  }, []);

  useEffect(() => {
    if (data?.fileMetadata) {
      setLoader(true);
      wrappedFetch(`tasks/file/${data?.fileMetadata.uuid}`, {}, 'GET', { fileDownload: true }).then((resp) => {
        if (resp.ok) {
          resp.blob().then((blob) => {
            setLoader(false);
            setImage(blob);
          });
        }
      });
    }
    if (data?.filledFields) {
      setFilledFields(data?.filledFields);
    }
    if (data?.correctFields) {
      setCorrectFields(data?.correctFields);
    }
    setPoints(data?.recommendedPoints);
  }, [taskIndex]);

  const handleNextTask = (e) => {
    setLoader(true);
    wrappedFetch('filled-championships/next-task', {
      realRoundIndex, realTaskIndex, points, filledChampId,
    }, 'POST').then((resp) => {
      let taskIndexToBeSet;
      let roundIndexToBeSet = realRoundIndex;
      if (realTaskIndex < 9) {
        taskIndexToBeSet = realTaskIndex + 1;
      } else {
        taskIndexToBeSet = 0;
      }
      if (realTaskIndex === 9) roundIndexToBeSet = realRoundIndex + 1;

      wrappedFetch(`filled-championships/review/${filledChampId}/${roundIndexToBeSet}/${taskIndexToBeSet}`).then((res) => {
        console.log({ res });
        setData(res);
        setTaskIndex(taskIndexToBeSet);
        setRoundIndex(roundIndexToBeSet);
      });
    });
  };

  console.log({ realTaskIndex });
  console.log({ data });

  const handleFinish = () => {
    setLoader(true);
    wrappedFetch('filled-championships/next-task', {
      realRoundIndex, realTaskIndex, points, filledChampId,
    }, 'POST').then((resp) => {
      setFilledChampId(null);
      setLoader(false);
      notification('Veiksmīgi izpildīts', 'success');
    });
  };

  const handlePointChange = (event) => {
    setPoints(event.target.value);
  };
  const ref = React.createRef();
  const lowerButtonRef = React.createRef();

  const options = {
    orientation: 'landscape',
  };

  const handleDownloadPDF = (toPdf) => {
    lowerButtonRef.current.style.display = 'none';
    toPdf();
  };
  return (
    <>
      <FancyBackdrop showLoader={showLoader} />
      <Pdf
        targetRef={ref}
        options={options}
        filename={`${data?.user?.name || ''}-${data?.roundNumber || ''}-${data?.taskNumber || ''}.pdf`}
        y={12}
        scale={0.9}
        onComplete={() => { lowerButtonRef.current.style.display = 'flex'; }}
      >
        {({ toPdf }) => (
          <Button
            onClick={() => handleDownloadPDF(toPdf)}
            variant="contained"
            color="primary"
            style={{ marginTop: '10px' }}
          >
            Ģenerēt PDF
          </Button>
        )}
      </Pdf>
      {data ? (
        <Box ref={ref} style={{ width: 1132, margin: 'auto' }}>
          <Box style={{ textAlign: 'center' }}>{data.title}</Box>
          <Box style={{ display: 'flex', justifyContent: 'center' }}>
            <h4 style={{
              background: 'lightgray', borderRadius: 10, padding: '0 8px 0 8px', marginRight: 30,
            }}
            >
              {`${data.roundNumber}/3 raunds`}
            </h4>
            <h4 style={{ background: 'lightgray', borderRadius: 10, padding: '0 8px 0 8px' }}>
              {`${data.taskNumber}/10 uzdevums`}
            </h4>
          </Box>
          {image && (
            <Box display="flex" justifyContent="center">
              <Box>
                <Box textAlign="center" fontSize="18px" fontWeight="600">Spēlētāja atbilde</Box>
                <Box className={classes.sudokuWrapper}>
                  <Box className={classes.imageWrapper}>
                    <img className={classes.sudokuImage} src={URL.createObjectURL(image)} alt="Bilde nav ielādēta" />
                  </Box>
                  <Box className={classes.inputFieldWrapper}>
                    {correctFields.map((row, rowIndex) => (
                      <Box key={rowIndex} className={classes.sudokuRow}>
                        { row.map((item, itemIndex) => (
                          item
                            ? (
                              <TextField
                                key={itemIndex}
                                value={filledFields[rowIndex][itemIndex] || '-'}
                                className={`${classes.inputField} ${correctFields[rowIndex][itemIndex] !== filledFields[rowIndex][itemIndex] ? classes.incorrectValue : ''} ${(filledFields[rowIndex][itemIndex] || '').length <= 1 ? classes.correctLength : (filledFields[rowIndex][itemIndex] || '').length < 4 ? classes.wideLength : classes.widerLength}`}
                                variant="outlined"
                              />
                            )
                            : <Box key={itemIndex} className={classes.inputField} />
                        )) }
                      </Box>
                    ))}
                  </Box>
                </Box>
              </Box>

              <Box>
                <Box textAlign="center" fontSize="18px" fontWeight="600">Pareizā atbilde</Box>
                <Box className={classes.sudokuWrapper}>
                  <Box className={classes.imageWrapper}>
                    <img className={classes.sudokuImage} src={URL.createObjectURL(image)} alt="Bilde nav ielādēta" />
                  </Box>
                  <Box className={classes.inputFieldWrapper}>
                    {correctFields.map((row, rowIndex) => (
                      <Box key={rowIndex} className={classes.sudokuRow}>
                        { row.map((item, itemIndex) => (
                          item
                            ? (
                              <TextField
                                key={itemIndex}
                                value={correctFields[rowIndex][itemIndex] || ''}
                                className={`${classes.inputField} ${classes.correctLength}`}
                                variant="outlined"
                              />
                            )
                            : <Box key={itemIndex} className={classes.inputField} />
                        )) }
                      </Box>
                    ))}
                  </Box>
                </Box>
              </Box>
            </Box>
          )}
          <Box>
            <Box display="flex" mt={3} justifyContent="center" alignItems="center" fontSize="16px" fontWeight="600">
              <TextField
                value={points}
                onChange={handlePointChange}
                style={{ width: 70, marginRight: 8 }}
                variant="outlined"
                label="Punkti"
                size="small"
                margin="dense"
              />
              no
              {' '}
              {data?.maxPoints}
            </Box>
            <Box ref={lowerButtonRef} display="flex" mt={3} justifyContent="center" alignItems="center">
              {data.taskNumber === 10 && data.roundNumber === 3 ? (
                <Button onClick={handleFinish} variant="contained" color="primary">
                  Pabeigt un apstiprināt
                </Button>
              ) : (
                <Button onClick={handleNextTask} variant="contained" color="primary">
                  Nākamais uzdevums
                </Button>
              ) }

            </Box>
          </Box>
        </Box>
      ) : ''}
    </>
  );
};

export default FilledChampionshipReview;
