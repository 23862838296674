import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button, TextField, Box, Tooltip,
} from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { wrappedFetch } from '../assets/helper';
import WaitingScreen from '../molecules/WaitingScreen';
import EMPTY_SUDOKU from '../assets/constants/sudoku';
import { secondsToHmsShort } from '../atoms/formatDate';
import EndScreen from '../molecules/EndScreen';
import FancyBackdrop from '../atoms/FancyBackdrop';
import Modal from '../molecules/Modal';

const useStyles = makeStyles({
  sudokuWrapper: {
    minWidth: 500,
    minHeight: 500,
    maxWidth: 500,
    maxHeight: 500,
    position: 'relative',
    margin: 'auto',
    zIndex: 5,
  },
  imageWrapper: {
    width: '100%',
    height: '100%',
  },
  sudokuImage: {
    width: '100%',
    height: '100%',
  },
  sudokuRow: {
    display: 'flex',
    flex: 1,
  },
  inputFieldWrapper: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    padding: ({ marginEnabled }) => (marginEnabled ? 54 : 0),
  },
  inputField: {
    flex: 1,
    '& fieldset': {
      border: 'unset',
    },
  },
  correctLength: {
    '& input': {
      textAlign: 'center',
      fontSize: ({ marginEnabled }) => (marginEnabled ? 26 : 36),
      padding: ({ marginEnabled }) => (marginEnabled ? '6px 14px' : '6px 17px'),
      color: '#7777DD',
    },
  },
  wideLength: {
    '& input': {
      textAlign: 'center',
      fontSize: ({ marginEnabled }) => (marginEnabled ? 18 : 25),
      padding: ({ marginEnabled }) => (marginEnabled ? '11px 5px' : '13px 7px'),
      color: '#ff9966',
    },
  },
  widerLength: {
    '& input': {
      textAlign: 'center',
      fontSize: ({ marginEnabled }) => (marginEnabled ? 10 : 16),
      padding: ({ marginEnabled }) => (marginEnabled ? '15px 4px' : '18px 5px'),
      color: '#ff9966',
    },
  },
  description: {
    position: 'absolute', width: '24px', height: '24px', top: 'calc(50% - 12px)', left: '125%',
  },
  help: {
    color: 'rgb(70,70,70)',
  },
  card: {
    marginBottom: 20,
  },
  cardContent: {
    paddingBottom: '16px !important',
  },
  cardContentRed: {
    paddingBottom: '16px !important',
    color: 'red',
  },
  buttons: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
});

export default function PlayerScreen({ user, notification }) {
  const [data, setData] = useState(null);
  const [image, setImage] = useState(null);
  const [render, setRender] = useState(0);
  const [filledFields, setFilledFields] = useState(EMPTY_SUDOKU);
  const [marginEnabled, setMarginEnabled] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const [seconds, setSeconds] = useState(0);
  const [requestInProgress, setRequestInProgress] = useState(false);
  const classes = useStyles({ marginEnabled });
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    let interval;
    if (data?.championship_starting_time || data?.round?.ending_time) {
      interval = setInterval(() => {
        setSeconds((seconds2) => seconds2 + 1);
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [data]);

  const fetchDataForPlayer = (init) => {
    setRequestInProgress(true);
    wrappedFetch('player').then((response) => {
      console.log({ response });
      if (response) {
        setData(response);
        if (response?.round?.task?.filled_fields) {
          setFilledFields(response.round.task.filled_fields);
        }
        if (response?.round?.task?.margin) {
          setMarginEnabled(true);
        } else {
          setMarginEnabled(false);
        }
        if (response.round?.task?.file_uuid) {
          wrappedFetch(`tasks/file/${response.round.task.file_uuid}`, {}, 'GET', { fileDownload: true }).then((resp) => {
            if (resp.ok) {
              resp.blob().then((blob) => {
                setRequestInProgress(false);
                setImage(blob);
              }).catch((er) => {
                setRequestInProgress(false);
                console.log({ er });
              });
            } else {
              setRequestInProgress(false);
            }
          }).catch((er) => {
            setRequestInProgress(false);
            console.log({ er });
          });
        } else {
          setRequestInProgress(false);
        }
      } else {
        setRequestInProgress(false);
      }
    }).catch((er) => {
      setRequestInProgress(false);
      console.log({ er });
    });
  };

  useEffect(() => {
    fetchDataForPlayer(true);
  }, []);

  const nextTask = () => {
    wrappedFetch('player/next_task', { task_id: data.round.task.id }, 'POST').then((response) => {
      if (response.status === 'success') {
        setFilledFields(EMPTY_SUDOKU);
        setImage(null);
        fetchDataForPlayer(false);
      }
    });
  };

  const handleSudokuInputChange = (rowIndex, itemIndex, event) => {
    if (((parseInt(event.target.value, 10) && !event.target.value.includes('0')) || event.target.value === '') && event.target.value.length < 6) {
      const array = filledFields;
      array[rowIndex][itemIndex] = event.target.value;
      setRender(render + 1);
      setDisableButton(true);
      wrappedFetch('player/field_filled', { filled_fields: array, task_id: data.round.task.id }, 'POST').finally(() => { setDisableButton(false); });
      setFilledFields(array);
    }
  };

  const startsAfterDynamical = () => {
    if (data && data.championship_starting_time) {
      const date = new Date(`${data.championship_starting_time}Z`);
      const newDate = new Date();
      return (date.getTime() - newDate.getTime()) < 0 ? 0 : Math.floor(((date.getTime() - newDate.getTime()) / 1000));
    }
    return 1;
  };

  const endsAfterDynamical = () => {
    if (data?.round?.ending_time) {
      const endingDate = new Date(`${data.round.ending_time}Z`);
      const nowDate = new Date();
      return (endingDate.getTime() - nowDate.getTime()) < 0 ? 0 : Math.floor(((endingDate.getTime() - nowDate.getTime()) / 1000));
    }
    return 1;
  };

  console.log({ ending: endsAfterDynamical() });

  useEffect(() => {
    if (startsAfterDynamical() <= 0 || endsAfterDynamical() <= 0) {
      if (!requestInProgress) {
        fetchDataForPlayer(false);
        console.log('fetch');
      }
    }
  });

  const deleteFilledChampionship = () => {
    wrappedFetch(`filled-championships/destroy/${data.is_there_filled_championship}`, null, 'DELETE').finally((resp) => {
      fetchDataForPlayer(false);
      setModalOpen(false);
    });
  };

  const modalButtons = (
    <div className={classes.buttons}>
      <Button
        onClick={deleteFilledChampionship}
        variant="contained"
        color="secondary"
        style={{ marginRight: 0 }}
      >
        Sākt no jauna
      </Button>
      <Button
        onClick={() => setModalOpen(false)}
        variant="contained"
        color="primary"
      >
        Atcelt
      </Button>
    </div>
  );

  return (
    <Box>
      <FancyBackdrop showLoader={requestInProgress} />
      {data && <h1 style={{ textAlign: 'center' }}>{data.title}</h1>}
      {data?.championship_starting_time && (
        <WaitingScreen
          championshipStartingTime={data.championship_starting_time}
          roundNumber={data.round_number}
          startsAfterDynamical={startsAfterDynamical}
          showPoints={data.show_points}
        />
      )}
      {data?.championship_ending_time && (
        <EndScreen
          championshipEndingTime={data.championship_ending_time}
          showAllPoints={data.show_all_points}
        />
      )}
      { data?.round && (
        <div>
          <div style={{ textAlign: 'center' }}>{data.round.task.title}</div>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <h4 style={{
              background: 'lightgray', borderRadius: 10, padding: '0 8px 0 8px', marginRight: 30,
            }}
            >
              {`${data.round.number}/3 raunds`}
            </h4>
            <h4 style={{
              background: 'lightgray', borderRadius: 10, padding: '0 8px 0 8px', marginRight: 30,
            }}
            >
              {`${data.round.task.number}/10 uzdevums`}
            </h4>
            <h4 style={{ background: 'lightgray', borderRadius: 10, padding: '0 8px 0 8px' }}>
              {`${data.round.task.points} punkti`}
            </h4>
          </div>
          {image && (
            <div style={{ display: 'flex', zIndex: 5 }}>
              <div style={{ flex: 1, maxWidth: 450 }} />
              <div className={classes.sudokuWrapper}>
                <div className={classes.imageWrapper}>
                  <img className={classes.sudokuImage} src={URL.createObjectURL(image)} alt="Bilde nav ielādēta" />
                </div>
                <div className={classes.inputFieldWrapper}>
                  {data.round.task.fillable_fields.map((row, rowIndex) => (
                    <div key={rowIndex} className={classes.sudokuRow}>
                      { row.map((item, itemIndex) => (
                        item
                          ? (
                            <TextField
                              key={itemIndex}
                              value={filledFields[rowIndex][itemIndex] || ''}
                              onChange={(event) => handleSudokuInputChange(rowIndex, itemIndex, event)}
                              className={`${classes.inputField} ${(filledFields[rowIndex][itemIndex] || '').length <= 1 ? classes.correctLength : (filledFields[rowIndex][itemIndex] || '').length < 4 ? classes.wideLength : classes.widerLength}`}
                              variant="outlined"
                            />
                          )
                          : <div key={itemIndex} className={classes.inputField} />
                      )) }
                    </div>
                  ))}
                </div>
              </div>
              <div style={{
                margin: '0 20px', fontSize: 14, flex: 1, maxWidth: 450,
              }}
              >
                <Card className={classes.card}>
                  <CardContent className={classes.cardContent}>
                    <p>{data.round.task.description}</p>
                    <p>Neskaidrību gadījumā zvanīt 23994030</p>
                  </CardContent>
                </Card>
                {data.round.task.red_text ? (
                  <Card className={classes.card}>
                    <CardContent className={classes.cardContentRed}>
                      <p>{data.round.task.red_text}</p>
                    </CardContent>
                  </Card>
                ) : ''}
              </div>
            </div>
          )}
          <div style={{
            display: 'flex', position: 'absolute', bottom: 20, left: 30, right: 30, justifyContent: 'space-between', zIndex: 1,
          }}
          >
            {data.round.task.number === 1 && data.round.number === 1 && data.testing && data.is_there_filled_championship
              ? (
                <Button style={{ height: 36 }} onClick={() => setModalOpen(true)} variant="contained" color="secondary">
                  Sākt no jauna
                </Button>
              ) : <div style={{ width: 193 }} />}
            <div style={{ textAlign: 'end' }}>
              <Tooltip
                arrow
                title={<div style={{ fontSize: 12, margin: 5 }}>Atpakaļ uz šo uzdevumu nevarēs atgriezties</div>}
              >
                <Button onClick={nextTask} variant="contained" color="primary" disabled={disableButton}>
                  {data.round.task.number === 10 ? 'Pabeigt raundu' : 'Nākamais uzdevums'}
                </Button>
              </Tooltip>
              <h3>{`Raunds beigsies pēc: ${secondsToHmsShort(endsAfterDynamical())}`}</h3>
            </div>

          </div>
        </div>
      )}
      <Modal
        title="Uzmanību"
        open={!!modalOpen}
        dynamicSize
        footer={modalButtons}
        setOpen={setModalOpen}
      >
        <p>Vai vēlies sākt no jauna? Šis izdzēsīs visas ievadītās vērtības.</p>
      </Modal>
    </Box>
  );
}
