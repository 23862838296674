import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  TextField, Button, Box, IconButton, Checkbox, FormControlLabel,
} from '@material-ui/core';
import { FaUpload } from 'react-icons/fa';
import Dropzone from 'react-dropzone';
import { wrappedFetch } from '../assets/helper';
import StyledDropzone from '../atoms/StyledDropzone';
import EMPTY_SUDOKU from '../assets/constants/sudoku';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  reportBugFormWrapper: {
    paddingTop: '6px',
    width: '80%',
    margin: 'auto',
    position: 'relative',
  },
  upload: {
    display: 'block',
    fontSize: '1rem',
    maxWidth: 220,
    cursor: 'pointer',
  },
  uploadText: {
    paddingRight: '10px',
    display: 'inline',
  },
  sudokuWrapper: {
    width: 500,
    height: 500,
    position: 'relative',
  },
  imageWrapper: {
    width: '100%',
    height: '100%',
  },
  sudokuImage: {
    width: '100%',
    height: '100%',
  },
  sudokuRow: {
    display: 'flex',
    flex: 1,
    borderLeft: ({ enableMargin }) => (enableMargin ? '1px solid red' : 'none'),
    borderRight: ({ enableMargin }) => (enableMargin ? '1px solid red' : 'none'),
  },
  sudokuFirstRow: {
    borderTop: ({ enableMargin }) => (enableMargin ? '1px solid red' : 'none'),
  },
  sudokuLastRow: {
    borderBottom: ({ enableMargin }) => (enableMargin ? '1px solid red' : 'none'),
  },
  inputFieldWrapper: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    padding: ({ enableMargin }) => (enableMargin ? 54 : 0),
  },
  inputField: {
    flex: 1,
    '& fieldset': {
      border: 'unset',
    },
    '& input': {
      fontSize: ({ enableMargin }) => (enableMargin ? 26 : 36),
      padding: ({ enableMargin }) => (enableMargin ? '6px 14px' : '6px 17px'),
      color: 'red',
    },
  },
});

export default function TasksForm({
  taskId, roundId, goBackToTable, notification,
}) {
  const [data, setData] = useState({
    title: '', number: '', points: '', description: '', redText: '',
  });
  const [file, setFile] = useState(null);
  const [enableMargin, setEnableMargin] = useState(false);
  const [render, setRender] = useState(0);
  const [fillableFields, setFillableFields] = useState(EMPTY_SUDOKU);
  const classes = useStyles({ enableMargin });
  const dropzoneRef = useRef(null);

  useEffect(() => {
    if (taskId) {
      wrappedFetch(`tasks/${taskId}`).then((res) => {
        console.log({ res });
        if (res) {
          setData({
            title: res.title,
            number: res.number,
            points: res.points,
            description: res.description,
            redText: res.red_text,
          });
          if (res.margin) {
            setEnableMargin(true);
          }
          if (res.fillable_fields) {
            setFillableFields(JSON.parse(res.fillable_fields));
          }
          if (res.file_metadata) {
            const fileMetadata = JSON.parse(res.file_metadata);
            if (fileMetadata.uuid) {
              wrappedFetch(`tasks/file/${fileMetadata.uuid}`, {}, 'GET', { fileDownload: true }).then((response) => {
                if (response.ok) {
                  response.blob().then((blob) => {
                    setFile(blob);
                  });
                }
              });
            }
          }
        }
      });
    }
  }, []);

  const handleInputChange = (field, value, val) => {
    setData({ ...data, [field]: value });
  };

  const createTask = () => {
    const formData = new FormData();
    formData.append('title', data.title);
    formData.append('number', data.number);
    formData.append('points', data.points);
    formData.append('description', data.description);
    formData.append('redText', data.redText);
    formData.append('fillableFields', JSON.stringify(fillableFields));
    formData.append('file', file);
    formData.append('roundId', roundId);
    formData.append('margin', enableMargin ? 1 : 0);
    wrappedFetch(`tasks${taskId ? `/${taskId}` : ''}`, formData, 'POST', { form: true }).then((response) => {
      console.log({ response });
      if (response.success === true) {
        notification('Veiksmīgi izpildīts', 'success');

        setData({
          title: '', number: '', points: '', description: '', redText: '',
        });
        setFillableFields(EMPTY_SUDOKU);
        goBackToTable();
      } else {
        notification('Kaut kas nogāja greizi', 'error');
      }
    }).catch((er) => {
      notification('Kaut kas nogāja greizi', 'error');
    });
  };

  const openUploadDialog = () => {
    if (dropzoneRef.current) {
      dropzoneRef.current.open();
    }
  };

  const handleSudokuInputChange = (rowIndex, itemIndex, event) => {
    const array = fillableFields;
    const digit = event.target.value.match(/\d+$/);
    if (digit && digit[0]) {
      console.log({ asdasdasd: digit[0] });
      array[rowIndex][itemIndex] = digit[0].slice(-1);
    }
    if (event.target.value === '') {
      array[rowIndex][itemIndex] = '';
    }
    setRender(render + 1);
    setFillableFields(array);
  };

  const uploadFiles = (uploadedFiles) => {
    console.log({ uploadedFiles });
    const acceptedFormats = ['png', 'jpg', 'jpeg'];
    let supportedFile = null;
    let unsupportedFile = false;
    uploadedFiles.forEach((el) => {
      const ext = el.name.split('.').pop().toLowerCase();
      if (acceptedFormats.includes(ext) && supportedFile === null) {
        supportedFile = el;
      } else {
        unsupportedFile = true;
      }
    });
    if (unsupportedFile) {
      notification(`Neatpazīts faila formāts, tiek akceptēti tikai: ${acceptedFormats.join(', ')}`, 'error');
    }
    if (supportedFile) {
      setFile(supportedFile);
    }
  };
  console.log({ file });

  return (
    <Dropzone ref={dropzoneRef} onDrop={uploadFiles} noClick noKeyboard>
      {({ getRootProps, getInputProps, isDragActive }) => (
        <Box className={classes.reportBugFormWrapper} {...getRootProps()}>
          {isDragActive && <StyledDropzone />}
          <input {...getInputProps()} />

          <Button onClick={goBackToTable} variant="contained" color="primary">
            Atpakaļ
          </Button>

          <form className={classes.root} noValidate autoComplete="off">
            <TextField
              onChange={(event) => handleInputChange('title', event.target.value)}
              value={data.title}
              id="title"
              label="Virsraksts"
              style={{ marginBottom: 10 }}
            />
            <TextField
              onChange={(event) => handleInputChange('number', event.target.value)}
              value={data.number}
              id="number"
              label="Uzdevuma numurs"
              style={{ marginBottom: 10 }}
            />
            <TextField
              onChange={(event) => handleInputChange('points', event.target.value.replace(/[^0-9]/g, ''))}
              value={data.points}
              id="points"
              label="Punkti"
              style={{ marginBottom: 10 }}
            />
            <TextField
              onChange={(event) => handleInputChange('description', event.target.value)}
              value={data.description}
              id="description"
              label="Informācija par uzdevumu"
              multiline
              variant="outlined"
              style={{ marginBottom: 10 }}
            />
            <TextField
              onChange={(event) => handleInputChange('redText', event.target.value)}
              value={data.redText}
              id="redText"
              label="Sarkanais teksts"
              multiline
              variant="outlined"
              style={{ marginBottom: 10 }}
            />
            <Box style={{ display: 'flex' }}>
              <FormControlLabel
                control={(
                  <Checkbox
                    checked={enableMargin}
                    onChange={() => setEnableMargin(!enableMargin)}
                  />
                )}
                label="Uzdevums ar apmalēm"
              />
            </Box>
          </form>
          <Box
            onClick={openUploadDialog}
            size="medium"
            className={classes.upload}
          >
            <Box className={classes.uploadText}>
              Pievienot bildi
            </Box>
            <IconButton
              size="small"
              style={{ display: 'inline', color: '#3f51b5' }}
            >
              <FaUpload />
            </IconButton>
          </Box>
          {file && (
            <div className={classes.sudokuWrapper}>
              <div className={classes.imageWrapper}>
                <img className={classes.sudokuImage} src={URL.createObjectURL(file)} alt="Bilde nav ielādēta" />
              </div>
              <div className={classes.inputFieldWrapper}>
                {fillableFields.map((row, rowIndex) => (
                  <div key={rowIndex} className={`${rowIndex === 0 ? classes.sudokuFirstRow : ''} ${rowIndex === 8 ? classes.sudokuLastRow : ''} ${classes.sudokuRow}`}>
                    { row.map((item, itemIndex) => (
                      <TextField
                        key={itemIndex}
                        value={fillableFields[rowIndex][itemIndex]}
                        onChange={(event) => handleSudokuInputChange(rowIndex, itemIndex, event)}
                        className={classes.inputField}
                        variant="outlined"
                      />
                    )) }
                  </div>
                ))}
              </div>
            </div>
          )}

          <Button onClick={createTask} variant="contained" color="primary">
            {taskId ? 'Rediģēt uzdevumu' : 'Izveidot uzdevumu'}
          </Button>
        </Box>
      )}
    </Dropzone>
  );
}
