import { API_SERVER } from './constants/environment';

const { localStorage } = window;

const handleLogoutFromPortal = async () => {
  await new Promise((resolve, reject) => {
    setTimeout(() => {
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      resolve('Logged out');
    }, 3000);
  });

  window.location.reload();
};

export const login = async (body, method = 'POST', hostname = `${API_SERVER}/api/login`) => {
  if (!body.email && !body.password && localStorage.getItem('body')) {
    body = JSON.parse(localStorage.getItem('body'));
  }
  console.log(JSON.stringify(body));
  localStorage.removeItem('token');
  const init = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
    mode: 'cors',
  };

  return new Promise((resolve, reject) => {
    try {
      fetch(`${hostname}`, init)
        .then((res) => res.json())
        .then((resData) => {
          if (resData.token) {
            localStorage.setItem('token', resData.token);
            localStorage.setItem('body', JSON.stringify(body));
          }
          resolve(resData);
        }).catch((error) => {
          console.log({ error });
          reject(error);
        });
    } catch (error) {
      if (typeof error.json === 'function') {
        try {
          const json = error.json();

          if (json.code === 401) {
            // handleLogout();
          }

          reject(json);
        } catch (_error) {
          reject(error);
        }
      }
      reject(error);
    }
  });
};

export const wrappedFetch = async (pathname = '', body = {}, method = 'GET', overrideOptions = {}, hostname = `${API_SERVER}/api/`) => {
  const token = await localStorage.getItem('token');
  const tokenParam = `token=${token}`;
  if (!overrideOptions.form) {
    body = {
      ...body,
      token,
    };
  }
  const ret = [];
  for (const key in body) {
    ret.push(`${encodeURIComponent(key)}=${encodeURIComponent(body[key])}`);
  }
  const query = `?${tokenParam}&${ret.join('&')}`;

  return new Promise((resolve, reject) => {
    try {
      fetch(`${hostname}${pathname}${method === 'GET' || overrideOptions.form ? query : ''}`, method === 'GET' ? {
        method,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      } : {
        method,
        headers: overrideOptions.form ? { Accept: 'application/json' } : {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: overrideOptions.form ? body : JSON.stringify(body),
      }).then((res) => (overrideOptions.fileDownload ? res : res.json()))
        .then((resData) => {
          if (resData.message === 'Token has expired') {
            handleLogoutFromPortal();
            reject(resData);
          } else {
            resolve(resData);
          }
        }).catch((error) => {
          console.log({ error });
          reject(error);
        });
    } catch (error) {
      if (typeof error.json === 'function') {
        try {
          const json = error.json();

          if (json.code === 401) {
            handleLogoutFromPortal();
          }

          reject(json);
        } catch (_error) {
          reject(error);
        }
      }
      reject(error);
    }
  });
};
