export default {
  palette: {
    type: 'dark',
    text: {
      primary: '#F7F7F7',
    },
    color: {
      paper: '#e6e6e6',
    },
    bottomNavigation: {
      roundedButton: {
        borderColor: '#717171',
        background: '#5d5d5d',
        active: {
          background: 'radial-gradient(circle, rgba(81, 161, 89,28%) 53%, rgba(55,152,65,1) 100%, rgba(58,155,43,0) 100%)',
        },
      },
      borderTop: 'unset',
    },
    poll: {
      threeDotIcon: {
        color: '#F7F7F7',
      },
      answer: {
        backgroundColor: 'rgba(255,255,255,0.12)',
        border: '1px solid rgba(255,255,255,0.22)',
        color: '#F7F7F7',
      },
    },
    attendees: {
      list: {
        hoverColor: 'rgba(238,238,238,0.05)',
      },
      emote: {
        defaultColor: 'rgb(255,255,255)',
      },
      controlButton: {
        background: 'rgba(20, 20, 20, 0.3)',
      },
      attendee: {
        youBadgeBackground: '#4f753c',
        presenterColor: '#22e222',
        actionColor: '#cac8c8',
      },
    },
    settings: {
      list: {
        hoverColor: 'rgba(238,238,238,0.05)',
        selectedColor: 'dodgerblue',
      },
    },
    presenterIcon: {
      default: {
        color: '#F7F7F7',
      },
    },
    rightSidePanel: {
      borderLeft: 'unset',
    },
    loginScreen: {
      input: {
        background: '#515558 !important',
        autofill: '0 0 0 100px #515558 inset !important',
        borderRadius: 'unset',
        color: 'dodgerblue',
      },
    },
    chat: {
      chatBody: {
        border: 'unset',
      },
      authorMessage: {
        border: '2px solid #83a583',
      },
      text: {
        border: '2px solid #424242',
        openBackgroundColor: '#5f5f5f',
        backgroundColor: '#424242',
      },
      systemText: {
        border: '2px solid #393939',
        openBackgroundColor: '#393939',
        backgroundColor: '#393939',
      },
      controlButton: {
        background: 'linear-gradient(to right, rgba(66,66,66,0.1),rgba(66,66,66,1) ,rgba(66,66,66,1))',
        color: '#fff',
      },
    },
    whiteboard: {
      readOnly: {
        color: '#e6e6e6',
      },
      strokeWidthPicker: {
        color: '#e6e6e6',
      },
      toolsIcon: {
        color: '#e6e6e6',
      },
      toolsIconSelected: {
        backgroundColor: '#3f51b5',
      },
      toolsControls: {
        dividerBackground: 'rgb(10,10,10)',
      },
      sheetSelection: {
        sheet: {
          color: '#e6e6e6',
        },
        selected: {
          backgroundColor: '#3f51b5',
          color: '#e6e6e6',
        },
        faEllipsisV: {
          color: '#e6e6e6',
        },
        faEllipsisVSelected: {
          color: '#e6e6e6',
        },
        plusIcon: {
          color: '#e6e6e6',
        },
      },
    },
    notificationHistory: {
      backgroundColor: '#424242',
      color: '#fff',
    },
  },
};
