import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Button, IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import Switch from '@material-ui/core/Switch';
import Modal from './Modal';
import { wrappedFetch } from '../assets/helper';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  buttons: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  bodyRow: {
    cursor: 'pointer',
  },
});
const ChampionshipsTable = ({
  rows, setEditId, setView, setChampionshipOpen, notification,
}) => {
  const classes = useStyles();
  const [modalOpen, setModalOpen] = useState(false);

  const handleEditChampionship = (id, e) => {
    e.stopPropagation();
    setEditId(id);
    setView('form');
  };

  const handleSeeFilledChampionship = (id, e) => {
    e.stopPropagation();
    setView('filled');
    setChampionshipOpen(id);
  };

  const handleCopyChampionship = (id, e) => {
    e.stopPropagation();
    wrappedFetch('championships/copy', { id }, 'POST').then((response) => {
      if (response.success === true) {
        notification('Veiksmīgi izpildīts', 'success');
      } else {
        notification('Kaut kas nogāja greizi', 'error');
      }
    }).catch((er) => {
      notification('Kaut kas nogāja greizi', 'error');
    });
  };

  const handleDeleteChampionship = () => {
    setView(null);
    wrappedFetch(`championships/${modalOpen}`, null, 'DELETE').then((response) => {
      setView('table');
      setModalOpen(false);
      if (response.success === true) {
        notification('Veiksmīgi izpildīts', 'success');
      } else {
        notification('Kaut kas nogāja greizi', 'error');
      }
    }).catch((er) => {
      notification('Kaut kas nogāja greizi', 'error');
    });
  };

  const openDeleteModal = (id, e) => {
    e.stopPropagation();
    setModalOpen(id);
  };

  const modalButtons = (
    <div className={classes.buttons}>
      <Button
        onClick={() => setModalOpen(false)}
        variant="contained"
        color="secondary"
        style={{ marginRight: 0 }}
      >
        Nē
      </Button>
      <Button
        onClick={handleDeleteChampionship}
        variant="contained"
        color="primary"
      >
        Jā
      </Button>
    </div>
  );

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Id</TableCell>
            <TableCell align="center">Virsraksts</TableCell>
            <TableCell align="center">Publicēts</TableCell>
            <TableCell align="center">Izveidots</TableCell>
            <TableCell align="center">Rediģēts</TableCell>
            <TableCell align="center">Skatīt spēlētāju aizpildītos</TableCell>
            <TableCell align="center">Rediģēt</TableCell>
            <TableCell align="center">Kopēt</TableCell>
            <TableCell align="center">Dzēst</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow className={classes.bodyRow} key={row.id} onClick={() => setChampionshipOpen(row.id)}>
              <TableCell component="th" scope="row">
                {row.id}
              </TableCell>
              <TableCell align="center">{row.title}</TableCell>
              <TableCell align="center">
                <Switch
                  checked={!!row.published}
                  name="published"
                  color="primary"
                />
              </TableCell>
              <TableCell align="center">{row.created_at}</TableCell>
              <TableCell align="center">{row.updated_at}</TableCell>
              <TableCell align="center">
                <Button onClick={(e) => handleSeeFilledChampionship(row.id, e)} variant="contained" color="primary">
                  Skatīt aizpildītos
                </Button>
              </TableCell>
              <TableCell align="center">
                <Button onClick={(e) => handleEditChampionship(row.id, e)} variant="contained" color="primary">
                  Rediģēt
                </Button>
              </TableCell>
              <TableCell align="center">
                <Button onClick={(e) => handleCopyChampionship(row.id, e)} variant="contained" color="primary">
                  Kopēt
                </Button>
              </TableCell>
              <TableCell align="center">
                <IconButton onClick={(e) => openDeleteModal(row.id, e)} aria-label="delete">
                  <DeleteIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Modal
        title="Uzmanību"
        open={!!modalOpen}
        dynamicSize
        footer={modalButtons}
        setOpen={setModalOpen}
      >
        <p>Vai tiešām vēlies dzēst šo čempionātu</p>
      </Modal>
    </TableContainer>
  );
};

export default ChampionshipsTable;
