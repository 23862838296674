import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Box } from '@material-ui/core';
import { wrappedFetch } from '../assets/helper';
import TasksTable from '../molecules/TasksTable';
import TasksForm from '../molecules/TasksForm';
import RoundsForm from '../molecules/RoundsForm';

const useStyles = makeStyles({

});

export default function RoundsScreen({
  user, champId, setChampId, notification,
}) {
  const classes = useStyles();
  const [view, setView] = useState('table');
  const [editTaskId, setEditTaskId] = useState(null);
  const [roundId, setRoundId] = useState(null);
  console.log({ roundId });

  const goBack = () => {
    setChampId(null);
  };

  const goBackToTable = () => {
    setEditTaskId(null);
    setRoundId(null);
    setView('table');
  };

  return (
    <>
      {view === 'table' && (
      <>
        <Button onClick={goBack} variant="contained" color="primary">
          Atpakaļ
        </Button>
        <h2 style={{ paddingTop: 45, textAlign: 'center' }}>Round 1</h2>
        <TasksTable notification={notification} setEditTaskId={setEditTaskId} setView={setView} champId={champId} roundNumber={1} setRoundId={setRoundId} />
        <h2 style={{ paddingTop: 45, textAlign: 'center' }}>Round 2</h2>
        <TasksTable notification={notification} setEditTaskId={setEditTaskId} setView={setView} champId={champId} roundNumber={2} setRoundId={setRoundId} />
        <h2 style={{ paddingTop: 45, textAlign: 'center' }}>Round 3</h2>
        <TasksTable notification={notification} setEditTaskId={setEditTaskId} setView={setView} champId={champId} roundNumber={3} setRoundId={setRoundId} />
      </>
      )}
      {view === 'form' && <TasksForm notification={notification} user={user} taskId={editTaskId} roundId={roundId} goBackToTable={goBackToTable} />}
      {view === 'rounds_form' && <RoundsForm notification={notification} user={user} roundId={roundId} goBackToTable={goBackToTable} />}
    </>
  );
}
