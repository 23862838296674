import React from 'react';
import {
  Typography, IconButton, Box, makeStyles,
} from '@material-ui/core';
import SystemUpdateAltIcon from '@material-ui/icons/SystemUpdateAlt';

const useStyles = makeStyles({
  DropzoneContent: {
    top: '50%',
    width: '100%',
    display: 'flex',
    position: 'absolute',
    transform: 'translateY(-50%)',
    alignItems: 'center',
    flexDirection: 'column',
  },
  DropzoneWrapper: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    zIndex: '1',
    background: 'rgba(255, 255, 255, 0.9)',
    border: '4px dashed rgba(0, 0, 0, 0.54)',
    borderRadius: '2',
  },
});

const Dropzone = () => {
  const classes = useStyles();
  return (
    <Box className={classes.DropzoneWrapper}>
      <Box className={classes.DropzoneContent}>
        <IconButton>
          <SystemUpdateAltIcon fontSize="large" />
        </IconButton>
        <Typography variant="h5" color="textSecondary">
          Drop File Here
        </Typography>
      </Box>
    </Box>
  );
};

export default Dropzone;
