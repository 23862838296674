export default {
  palette: {
    type: 'light',
    text: {
      primary: 'rgba(0, 0, 0, 0.87)',
    },
    color: {
      paper: '#e6e6e6',
    },
    bottomNavigation: {
      roundedButton: {
        borderColor: '#ccc',
        background: 'transparent',
        active: {
          background: 'radial-gradient(circle, rgba(81, 161, 89,0) 53%, rgba(55,152,65,1) 100%, rgba(58,155,43,0) 100%)',
        },
      },
      borderTop: '1px solid #ccc',
    },
    poll: {
      threeDotIcon: {
        color: '#666',
      },
      answer: {
        backgroundColor: '#fff',
        border: '1px solid #3f51b5',
        color: '#3f51b5',
      },
    },
    attendees: {
      list: {
        hoverColor: 'rgba(238,238,238,0.63)',
      },
      emote: {
        defaultColor: 'rgba(0, 0, 0, 0.54)',
      },
      controlButton: {
        background: '#f1efef',
      },
      attendee: {
        youBadgeBackground: '#4f753c',
        presenterColor: '#22b522',
        actionColor: '#716f6f',
      },
    },
    settings: {
      list: {
        hoverColor: 'rgba(238,238,238,0.63)',
        selectedColor: '#3f51b5',
      },
    },
    presenterIcon: {
      default: {
        color: '#444',
      },
    },
    rightSidePanel: {
      borderLeft: '1px solid #ccc',
    },
    loginScreen: {
      input: {
        background: 'inherit',
        autofill: '0 0 0 100px #fff inset !important',
        borderRadius: 'inherit',
        color: '#3f51b5',
      },
    },
    chat: {
      chatBody: {
        border: '1px solid #eee',
      },
      authorMessage: {
        border: '2px solid #eee',
      },
      text: {
        border: '2px solid #eee',
        openBackgroundColor: '#bdbdbd',
        backgroundColor: '#ececec',
      },
      systemText: {
        border: '2px solid #c5deed',
        openBackgroundColor: '#c5deed',
        backgroundColor: '#c5deed',
      },
      controlButton: {
        background: 'linear-gradient(to right, rgba(241,239,239,0.1),rgba(241,239,239,1) ,rgba(241,239,239,1))',
        color: 'rgba(0, 0, 0, 0.54)',
      },
    },
    whiteboard: {
      readOnly: {
        color: '#5a6872',
      },
      strokeWidthPicker: {
        color: '#5a6872',
      },
      toolsIcon: {
        color: '#5a6872',
      },
      toolsIconSelected: {
        backgroundColor: '#5a6872',
      },
      toolsControls: {
        dividerBackground: '#eee',
      },
      sheetSelection: {
        sheet: {
          color: '#444E56',
        },
        selected: {
          backgroundColor: '#5a6872',
          color: 'white',
        },
        faEllipsisV: {
          color: '#5a6872',
        },
        faEllipsisVSelected: {
          color: 'white',
        },
        plusIcon: {
          color: '#444E56',
        },
      },
    },
    notificationHistory: {
      backgroundColor: '#fff',
      color: 'rgba(0, 0, 0, 0.87)',
    },
  },
};
